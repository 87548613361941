import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";
import { eventsData } from "../../../data/events";
import Description from "../../common/Description";

function FestivalPage() {
	const navigate = useNavigate();

	// get id from url
	const { id } = useParams();

	const festival = eventsData.find(
		(festival) => festival.id === id && festival.type === "festival"
	);

	useEffect(() => {
		if (!festival) {
			navigate("/notfound");
		}
	}, [festival]);

	const content = (
		<div>
			{festival && (
				<>
					<PageTitle title={festival.title} />

					<p className="text-slate-400 pb-4">{festival.summary}</p>

					{festival.description && (
						<Description description={festival.description} />
					)}
				</>
			)}
		</div>
	);

	return <PageLayout children={content} />;
}

export default FestivalPage;
