function WelcomeBlock() {
	return (
		<div className="bg-purple-100 shadow rounded-lg p-6 mb-8">
			<h2 className="text-2xl font-bold mb-2 text-4xl">
				Welcome,
				<br />
				<span className="text-2xl">
					Yakthung Wensa Chumbho UK is a sister organisation of Kirat
					Yakthung Chumlung UK
				</span>
			</h2>
			<p className="text-gray-600 mb-4 text-sm">
				[ Celebrating our heritage and building our future together ]
			</p>
			<p className="leading-6">
				We're dedicated to preserving our culture and supporting our
				community members. Explore our site to learn more about our
				initiatives, events, and resources.
			</p>
		</div>
	);
}

export default WelcomeBlock;
