import React from "react";
import PageLayout from "../layout/PageLayout";
import { Link } from "react-router-dom";

function NotFoundPage() {
	const content = (
		<div className="flex flex-col items-center justify-center pt-[140px]">
			<h1 className="text-6xl font-bold text-blue-600">404</h1>
			<p className="text-2xl text-gray-700 mt-4">Page Not Found</p>
			<p className="text-lg text-gray-500 mt-2">
				Sorry, the page you are looking for does not exist.
			</p>
			<Link
				to="/"
				className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
			>
				Go Back Home
			</Link>
		</div>
	);

	return <PageLayout children={content} />;
}

export default NotFoundPage;
