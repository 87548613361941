import React from "react";

function Description({ description }) {
	return (
		<>
			{description.map((paragraph, index) => {
				switch (paragraph.type) {
					case "p":
						return (
							<p key={index} className="leading-6">
								{paragraph.content}
							</p>
						);
					case "h1":
					case "h2":
						return (
							<h2
								key={index}
								className="text-xl font-semibold my-2"
							>
								{paragraph.content}
							</h2>
						);
					case "h3":
						return (
							<h3
								key={index}
								className="text-lg font-semibold my-2"
							>
								{paragraph.content}
							</h3>
						);
					case "ul":
						return (
							<ul key={index} className="list-disc pl-6">
								{paragraph.content.map((item, i) => (
									<li key={i} className="py-2 leading-4">
										{item}
									</li>
								))}
							</ul>
						);
					default:
						break;
				}
			})}
		</>
	);
}

export default Description;
