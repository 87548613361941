/**
 * Events dates
 *
 * - Date ( Year, Month, Day )
 * - Month starts from 0 (January) to 11 (December)
 */

export const eventsData = [
	{
		id: "0a1b4191-fa69-4aa2-a541-0e30b095c79e",
		title: "11th General Convention - 2024",
		description: [
			{
				type: "p",
				content: "Held by KYC UK",
			},
			{
				type: "p",
				content:
					"11th General Convention - 2024. The convention will be held on 13th September 2024",
			},
		],
		start: new Date(2024, 8, 13),
		end: new Date(2024, 8, 13),
		type: "event",
	},
	{
		id: "0a1b4191-fa69-4aa2-a541-0e30b095c79f",
		title: "11th General Election for KYC UK - 2024",
		description: [
			{
				type: "p",
				content: "Held by KYC UK",
			},
			{
				type: "p",
				content:
					"11th General Election for KYC UK - 2024. The election will be held on 14th September 2024",
			},
		],
		start: new Date(2024, 8, 14),
		end: new Date(2024, 8, 14),
		type: "event",
	},
	{
		id: "0a1b4191-fa69-4aa2-a541-0e30b095c79g",
		title: "Balihang Tangnam",
		summary: "Celebration of Balihang Tangnam",
		description: [
			{
				type: "p",
				content: "Celebration of Balihang Tangnam",
			},
		],
		start: new Date(2024, 9, 24), // Example: September 15, 2024
		end: new Date(2024, 9, 24),
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/festivals%2Fvillage-limbu.jpg?alt=media&token=a06c930d-684e-4d6d-bcac-8d7232977d90",
		type: "festival",
	},
	{
		id: "0a1b4191-fa69-4aa2-a541-0e30b095c79h",
		title: "Chasok Tangnam",
		summary: "Celebration of Chasok Tangnam",
		description: [
			{
				type: "p",
				content:
					"Chasok Tangnam is indeed a festival of the Limbu people, also known as Kirat Limbu or Yakthung, which falls on the full moon day of the month of Senchengla, corresponding to the Mangsir month in the Nepali calendar. This important cultural celebration has deep roots in Limbu traditions and holds great significance for the community.",
			},
			{
				type: "h3",
				content: "Festival Significance",
			},
			{
				type: "p",
				content:
					"Chasok Tangnam is considered the most important festival for the Kirat Limbu people. It serves multiple purposes:",
			},
			{
				type: "ul",
				content: [
					"Harvest Thanksgiving: The festival developed as a way for Limbus to express gratitude to mother nature for the year's harvest",
					"Ancestral Reverence: It's an occasion to thank their ancestors for passing down agricultural knowledge and traditions.",
					"Religious Observance: Limbus worship and thank their deities, particularly the Ancestor Goddess Yuma Sammang and God Theba, for a successful harvest.",
				],
			},
		],
		start: new Date(2024, 10, 20),
		end: new Date(2024, 10, 20),
		type: "festival",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/festivals%2FFirefly%20a%20remote%20village%20in%20eastern%20part%20of%20nepal%20with%20some%20people%20from%20kirat%20limbu%20community%20in%20a%20g.jpg?alt=media&token=aee76219-d7e3-4db8-a81c-376e1122e881",
	},
	{
		id: "0a1b4191-fa69-4aa2-a541-0e30b095c79i",
		title: "Sisekpa Tangaam",
		summary: "Celebration of Sisekpa Tangnam",
		description: [
			{
				type: "p",
				content:
					"Sisekpa Tangnam is a festival of the Limbu people, also known as Kirat Limbu or Yakthung, which falls on the full moon day of the month of Poush, corresponding to the month of December in the Nepali calendar. This important cultural celebration has deep roots in Limbu traditions and holds great significance for the community.",
			},
		],
		start: new Date(2025, 6, 4),
		end: new Date(2025, 6, 6),
		type: "festival",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/festivals%2Fvillage-limbu.jpg?alt=media&token=a06c930d-684e-4d6d-bcac-8d7232977d90",
	},
];
