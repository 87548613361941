import React from "react";
import PageLayout from "../layout/PageLayout";

function GalleryPage() {
	const content = <div>Gallery Page</div>;

	return <PageLayout children={content} />;
}

export default GalleryPage;
