import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { MdOutlineEventAvailable } from "react-icons/md";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { eventsData } from "../../data/events";
import PageLayout from "../layout/PageLayout";
import PageTitle from "../common/PageTitle";
import Accordion from "../common/Accordion";
import Button from "../common/Button";

// Setup moment localizer for calendar
const localizer = momentLocalizer(moment);

function CalendarPage() {
	const navigate = useNavigate();

	const [events, setEvents] = useState(eventsData);

	// State for search query and filtered events
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredEvents, setFilteredEvents] = useState(events);

	const handleSelectSlot = ({ start, end }) => {
		const title = window.prompt("Enter a new event title");
		if (title) {
			setEvents((prevEvents) => [
				...prevEvents,
				{
					start,
					end,
					title,
				},
			]);
		}
	};

	const handleSelectEvent = (event) => {
		if (!!!event) return;

		switch (event.type) {
			case "festival":
				console.log("event", event.id);
				navigate("/festival/" + event.id);
				break;
			case "event":
			default:
				navigate("/coming-soon");
				break;
		}
	};

	const list = filteredEvents.map((event) => {
		let content = (
			<>
				<p>{event.summary}</p>
				<Button text="View" onClick={() => handleSelectEvent(event)} />
			</>
		);
		return (
			<Accordion key={event.id} title={event.title} content={content} />
		);
	});

	// Handle search input change
	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		// Filter events based on title
		const filtered = events.filter((event) =>
			event.title.toLowerCase().includes(query)
		);
		setFilteredEvents(filtered);
	};

	const icon = <MdOutlineEventAvailable size={50} />;

	const content = (
		<div>
			<PageTitle
				title="Calendar"
				subtitle="calendar for the year 2024"
				icon={icon}
			/>

			<Calendar
				localizer={localizer}
				events={filteredEvents}
				startAccessor="start"
				endAccessor="end"
				style={{ height: 500 }}
				selectable={false} // Enable selecting to add new events
				onSelectSlot={handleSelectSlot} // Handle adding new events
				defaultView="month" // Default to month view
				views={["month"]} // Available views
				popup={true}
			/>

			<p className="my-4 mt-10 text-3xl font-bold flex gap-[6px]">
				<MdOutlineEventAvailable
					size={32}
					className="pt-1 text-primary"
				/>
				Events
			</p>

			{/* Search Input */}
			<div className="flex justify-start items-start mt-4">
				<input
					type="text"
					value={searchQuery}
					onChange={handleSearch}
					placeholder="Search events"
					className="p-2 border border-gray-300 rounded-md"
				/>
			</div>
			<div className="my-4">{list}</div>
		</div>
	);

	return <PageLayout children={content} />;
}

export default CalendarPage;
