import React from "react";

import { Link } from "react-router-dom";

import logo from "../../images/logo-55x55.png";

function Header() {
	return (
		<header className="bg-lightGray body-font">
			<div className="container mx-auto max-w-6xl px-5 py-5 flex flex-wrap flex-col md:flex-row items-center">
				<Link
					to="/"
					className="flex title-font font-medium items-center mb-4 md:mb-0"
				>
					<img src={logo} alt="logo" />
					<span className="ml-3 text-xl">
						Yakthung Wensa Chumbho UK
					</span>
				</Link>
				<nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
					<div className="navbar">
						<Link to="/" className="mr-5 hover:text-red-400">
							Home
						</Link>
						<Link
							to="/calendar"
							className="mr-5 hover:text-red-400"
						>
							Calendar
						</Link>

						<Link to="/team" className="mr-5 hover:text-red-400">
							Team
						</Link>

						{/* Dropdown menu */}
						<div className="dropdown hidden">
							<button className="dropbtn">
								<Link to="/festivals">Festivals</Link>
							</button>
							<div className="dropdown-content">
								<a href="#">Sisekpa Tangnam</a>
								<a href="#">Chasok Tangnam</a>
								<a href="#">Balhang Tangnam</a>
							</div>
						</div>

						<Link
							to="/contact-us"
							className="mr-5 hover:text-red-400"
						>
							Contact Us
						</Link>
					</div>
				</nav>
			</div>
		</header>
	);
}

export default Header;
