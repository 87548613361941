import React from "react";
import PageLayout from "../layout/PageLayout";

import { members } from "../../data/members";
import PageTitle from "../common/PageTitle";

function TeamPage() {
	const content = (
		<div>
			<PageTitle title="Yakthung Wensa Chumbho UK Committee" />
			<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
				{members.map((member) => (
					<MemberCard key={member.id} member={member} />
				))}
			</div>
		</div>
	);

	return <PageLayout children={content} />;
}

const MemberCard = ({ member }) => {
	return (
		<div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md">
			{member.imageUrl ? (
				<img
					src={member.imageUrl}
					alt={`${member.name}'s profile picture`}
					width={64}
					height={64}
					className="rounded-full"
				/>
			) : (
				<div className="rounded-full bg-slate-200 w-[64px] h-[64px]"></div>
			)}
			<div>
				<h2 className="text-xl font-semibold text-gray-800">
					{member.name}
				</h2>
				<p className="text-gray-600">{member.description}</p>
			</div>
		</div>
	);
};

export default TeamPage;
